import React, { useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'

import {
	FormInput,
	PhoneInput,
} from './ui'
import {
	ContentMainHeading,
	ContentSubHeading,
} from './view-structure.component'
import Input from 'Components/ui/form-input.component'

import { TranslationContext, ConstantsContext } from 'Src/context'
import EmailVerificationDialog from './EmailVerification/EmailVerificationDialog'

const FormInputStyled = styled(FormInput)`
	margin-bottom: 16px;
`
const PhoneInputStyled = styled(PhoneInput)`
	margin-bottom: 16px;
`

/**
 * @param {Object} props
 * @param {import('Src/types/app-state.types').AppError[]} props.formErrors
 */
const MainGuestContactsFields = ({
	email,
	phone,
	hasOrderedParking,
	carPlate,
	phoneCountryCallingCode,
	phoneCountryCode,
	isEmailFieldVisible,
	updateField,
	formErrors,
	onEmailVerificationSubmit,
	onEmailVerificationResend,
}) => {
	const translation = useContext(TranslationContext)
	const { constants: { ACTIVE_PRODUCT_KEYS } } = useContext(ConstantsContext)

	const hasEmailError = useMemo(() => formErrors.includes('email'), [formErrors])
	const hasPhoneError = useMemo(() => formErrors.includes('phone'), [formErrors])
	const hasCarPlateError = useMemo(() => formErrors.includes('carPlate'), [formErrors])

	const handleEmailVerificationCode = useCallback((code, clearCodeCallback) => {
		onEmailVerificationSubmit(code, clearCodeCallback)
	}, [])

	const handleEmailVerificationResend = useCallback(() => {
		onEmailVerificationResend()
	}, [])

	return (
		<>
			<ContentMainHeading>
				{translation.t('Contact details of the main guest')}
			</ContentMainHeading>

			<ContentSubHeading>
				{/* eslint-disable max-len */}
				{translation.t('We do not have a front desk, hence we’ll use your contact information for communication and sharing important info. No spam, we promise.')}
			</ContentSubHeading>

			{!!isEmailFieldVisible && (
				<FormInputStyled
					label={`${translation.t('Email')}*`}
					helperText={translation.t('We need this to communicate with you since we don’t have a front desk')}
					name="email"
					type="email"
					value={email}
					handleChange={(value) => updateField('email', value)}
					isValid={!hasEmailError}
				/>
			)}

			<PhoneInputStyled
				label={`${translation.t('Phone number')}*`}
				value={{
					phone,
					phoneCountryCallingCode,
					phoneCountryCode,
				}}
				handleChange={(value) => updateField('phone', value)}
				isValid={!hasPhoneError}
			/>
			{
				hasOrderedParking && (
					<Input
						label={`${translation.t('Vehicle plate number')}*`}
						helperText={translation.t('The number is needed so we could fulfill your parking order.')}
						exclamationMark
						value={carPlate}
						handleChange={
							(input) => updateField(
								'carPlate',
								{ productKey: ACTIVE_PRODUCT_KEYS.parking, carPlate: input },
							)
						}
						isValid={!hasCarPlateError}
					/>
				)
			}

			<EmailVerificationDialog
				onSubmit={handleEmailVerificationCode}
				onResend={handleEmailVerificationResend}
			/>
		</>
	)
}

export default MainGuestContactsFields
