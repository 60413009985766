import React, { useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { FormInput, RadioButtons, SelectCountry } from './ui'
import { ContentMainHeading } from './view-structure.component'

import { ConstantsContext, TranslationContext } from 'Src/context'
import GuestAdditionalDocumentFields from 'Components/guest-additional-document-fields'

const FormInputStyled = styled(FormInput)`
	margin-bottom: 16px;
`
const RadioButtonsStyled = styled(RadioButtons)`
	margin-bottom: 16px;
`
const SelectCountryStyled = styled(SelectCountry)`
	margin-bottom: 16px;
`

const AdditionalDetailsFields = ({
	documentType,
	documentNumber,
	documentIssueCountry,
	documentIssueProvince,
	documentDetailsRequired,
	additionalDocumentFieldsRequired,
	visitedCountryCode,
	visitedCountryRequired,
	nextDestinationRequired,
	destinationCountryCode,
	destinationCity,
	documentTypes,
	propertyCountry,
	updateField,
	formErrors,
}) => {
	const translation = useContext(TranslationContext)
	const { constants: { DOCUMENT_TYPES } } = useContext(ConstantsContext)

	useEffect(() => {
		if (documentTypes?.length === 1) {
			const existingDocumentType = documentTypes[0]

			if (existingDocumentType?.value !== documentType?.value) {
				updateField('documentType', existingDocumentType?.value)
			}
		}
	}, [documentTypes, documentType])

	const selectedDocumentType = useMemo(() => {
		if (documentTypes?.length === 1 || !documentType) {
			return documentTypes[0]
		} else {
			return documentType && DOCUMENT_TYPES.find(({ value }) => value === documentType)
		}
	}, [documentTypes, documentType])

	const documentIssueProvinceRequired = useMemo(() => {
		return documentIssueCountry === 'IT'
	}, [documentIssueCountry])

	return (
		<>
			<ContentMainHeading>{translation.t('Additional Details')}</ContentMainHeading>

			{documentDetailsRequired && (
				<>
					{documentTypes?.length > 1 && (
						<RadioButtonsStyled
							label={`${translation.t('Select Document type')}*`}
							name="document-type"
							options={documentTypes}
							value={selectedDocumentType}
							handleChange={({ value }) => updateField('documentType', value)}
							isValid={!formErrors?.includes('documentType')}
						/>
					)}
					<FormInputStyled
						label={`${selectedDocumentType
							? selectedDocumentType.label
							: translation.t('Identity document')
						} number*`}
						name={translation.t('document number')}
						type="text"
						value={documentNumber}
						handleChange={(value) => updateField(
							'documentNumber',
							(value || '').replace(/[^(\w|\d)]/gi, ''),
						)}
						isValid={!formErrors?.includes('documentNumber')}
					/>

					{additionalDocumentFieldsRequired && (
						<GuestAdditionalDocumentFields
							documentIssueCountry={documentIssueCountry}
							documentIssueProvince={documentIssueProvince}
							documentIssueProvinceRequired={documentIssueProvinceRequired}
							updateField={updateField}
							formErrors={formErrors}
						/>
					)}
				</>
			)}
			{visitedCountryRequired && (
				<SelectCountryStyled
					label={`${translation.t('Country you\'re entering {propertyCountry} from', { propertyCountry })}*`}
					placeholder={translation.t('Select country')}
					name="previous country"
					value={visitedCountryCode}
					onChange={({ value }) => updateField('visitedCountryCode', value)}
					isValid={!formErrors?.includes('visitedCountryCode')}
				/>
			)}
			{nextDestinationRequired && (
				<>
					<SelectCountryStyled
						label={`${translation.t('Country of your next destination')}*`}
						placeholder={translation.t('Select country')}
						name="next country"
						value={destinationCountryCode}
						onChange={({ value }) => updateField('destinationCountryCode', value)}
						isValid={!formErrors?.includes('destinationCountryCode')}
					/>
					<FormInputStyled
						label={`${translation.t('City of your next destination')}*`}
						name={translation.t('next city')}
						type="text"
						value={destinationCity}
						handleChange={(value) => updateField('destinationCity', value)}
						isValid={!formErrors?.includes('destinationCity')}
					/>
				</>
			)}
		</>
	)
}

export default AdditionalDetailsFields
